$.fn.gallery2Plugin = function (options) {
    var pluginName = "gallery2Plugin";

    // default options.
    var settings = $.extend({
        loadedFabasoftElements: false,
        borderWidth: 2,
        defaultRowHeight: 130
    }, options);
    // Do something to each element here

    var siteLang = document.documentElement.lang,
        labels = {
            en: {
                gallery: "Gallery",
                download: "Download image number",
                zoomIn: "Zoom in image",
                zoomOut: "Zoom out image",
                zoomInMax: "Maximum zoom level reached",
                zoomOutMax: "Minimum zoom level reached",
                close: "Close gallery",
                goForward: "Show next image",
                goBackward: "Show previous image",
                goToImage: "Show image",
                outOf: "out of",
                opensInOverlay: "Opens image in a gallery overlay"
            },
            de: {
                gallery: "Galerie",
                download: "Bild herunterladen Nummer",
                zoomIn: "Bild zoomen Stufe",
                zoomOut: "Bild rauszoomen Stufe",
                zoomInMax: "Maximale Zoom-Stufe erreicht",
                zoomOutMax: "Minimale Zoom-Stufe erreicht",
                close: "Galerie schließen",
                goForward: "zum nächsten Bild",
                goBackward: "zum vorherigen Bild",
                goToImage: "zeige Bild ",
                outOf: "von",
                opensInOverlay: "Öffnet Bild in einem Galerie-Overlay"
            }
        },
        classes = {
            galleryList: '.gallery__list',
            galleryListItem: '.gallery__list-item',
            galleryThumbnailLinks: '.gallery__thumbnail-link',
            galleryThumbnails: '.gallery__thumbnail',
            overlay: '.overlay',
            overlayClosed: '.overlay--closed',
            overlayHeader: '.overlay__header',
            overlayButtons: '.overlay__buttons',
            overlayButton: '.overlay__button',
            overlayClose: '.overlay__button--close',
            overlayDownload: '.overlay__button--download',
            overlayZoomIn: '.overlay__button--zoom-in',
            overlayZoomOut: '.overlay__button--zoom-out',
            overlayTitle: '.overlay__title',
            overlayContent: '.overlay__content',
            overlayControls: '.overlay__controls',
            overlayControl: '.overlay__control',
            overlayControlForward: '.overlay__control--forward',
            overlayControlBackward: '.overlay__control--backward',
            overlayGalleryListItem: '.overlay__gallery-list-item',
            overlayGalleryListItemActive: '.overlay__gallery-list-item--active'
        },
        classNames = {
            galleryList: classes['galleryList'].replace(/^\./, ""),
            galleryListItem: classes['galleryListItem'].replace(/^\./, ""),
            galleryThumbnailLinks: classes['galleryThumbnailLinks'].replace(/^\./, ""),
            galleryThumbnails: classes['galleryThumbnails'].replace(/^\./, ""),
            overlay: classes['overlay'].replace(/^\./, ""),
            overlayClosed: classes['overlayClosed'].replace(/^\./, ""),
            overlayHeader: classes['overlayHeader'].replace(/^\./, ""),
            overlayButtons: classes['overlayButtons'].replace(/^\./, ""),
            overlayButton: classes['overlayButton'].replace(/^\./, ""),
            overlayClose: classes['overlayClose'].replace(/^\./, ""),
            overlayDownload: classes['overlayDownload'].replace(/^\./, ""),
            overlayZoomIn: classes['overlayZoomIn'].replace(/^\./, ""),
            overlayZoomOut: classes['overlayZoomOut'].replace(/^\./, ""),
            overlayTitle: classes['overlayTitle'].replace(/^\./, ""),
            overlayContent: classes['overlayContent'].replace(/^\./, ""),
            overlayControls: classes['overlayControls'].replace(/^\./, ""),
            overlayControl: classes['overlayControl'].replace(/^\./, ""),
            overlayControlForward: classes['overlayControlForward'].replace(/^\./, ""),
            overlayControlBackward: classes['overlayControlBackward'].replace(/^\./, ""),
            overlayGalleryListItem: classes['overlayGalleryListItem'].replace(/^\./, ""),
            overlayGalleryListItemActive: classes['overlayGalleryListItemActive'].replace(/^\./, "")
        }

    function getLabel(label) {
        var lang = labels[siteLang] ? siteLang : "en";

        return labels[lang][label];
    }

    return main(this);

    function main(galleries) {
        var fabasoftGalleries = [];

        galleries.each(function (i, elm) {
            if (elm.classList.contains('js-gallery--fabasoft')) {
                fabasoftGalleries.push(elm);
            } else {
                initGallery(elm);
            }
        });


        $(fabasoftGalleries).each(function (i, elm) {
            var $elm = $(elm),
                nextGallery = fabasoftGalleries[i + 1] ? fabasoftGalleries[i + 1] : null;

            $elm.data("nextGallery", nextGallery);
        });

        if (fabasoftGalleries.length > 0) {
            initGallery(fabasoftGalleries[0]);
        }
    };

    function addFabasoftElement(element, defaultCopyright, galleryName, index, $elem) {
        var elementName = (element.objname && element.objname.value ? element.objname.value : null),
            elementDescription = (element.dadescription && element.dadescription.value ? element.dadescription.value : null),
            elementSubject = (element.objsubject ? element.objsubject.value : null),
            elementCopyright = (element.daliccopyright && element.daliccopyright.value ? "© " + element.daliccopyright.value : "© " + defaultCopyright);

        var altValue = elementDescription ? elementDescription : galleryName;


        // elementSubject || elementDescription || galleryName: 05.05.2021
        var overviewValue = elementSubject ? elementSubject : (elementDescription ? elementDescription : galleryName);
        var delimiter = (overviewValue && elementCopyright.length > 0) ? " - " : " ";

        var detailValue = overviewValue + delimiter + elementCopyright;
        var isVideo = element.contmimetype.value.indexOf("video") > -1;


        var data = $("<li class=\"" + classNames['galleryListItem'] + "\"><a data-index=\"" + index + "\" class=\"" + classNames['galleryThumbnailLinks'] + "\" href=\"" + element.largepreviewurl.value +
            "\" title=\"" + getLabel("opensInOverlay") + "\">" +

            "<img class=\"" + classNames['galleryThumbnails'] + "\" src=\"" + element.thumbnailurl.value +
            "\" alt=\"" + altValue +
            "\" data-subhtml=\"" + detailValue +
            "\" title=\"" + elementCopyright +
            "\" data-big-src=\"" + element.largepreviewurl.value +
            "\" data-download=\"" + element.downloadurl.value +
            "\" data-video-url=\"" + (isVideo ? element.mediaurls[0].value : "") +
            "\" data-element-description=\"" + elementDescription +
            "\" data-element-objname=\"" + elementName +
            "\" data-element-subject=\"" + elementSubject +
            "\" />" +
            "<div aria-hidden=\"true\" class=\"gallery__thumbnail-caption\">" +
            overviewValue +
            "</div></a></li>")
        $elem.find('.gallery__list').append(data)
    }

    function initGallery(gallery) {
        var gallery = gallery,

            galleryList = getGalleryList(),
            galleryListItems = gallery.querySelectorAll(classes['galleryListItem']),
            galleryThumbnailLinks = gallery.querySelectorAll(classes['galleryThumbnailLinks']),
            galleryThumbnails = gallery.querySelectorAll(classes['galleryThumbnails']),
            galleryWidth = galleryList.clientWidth - settings.borderWidth,
            galleryId = gallery.getAttribute('data-gallery-id')
        downloadEnabled = (gallery.getAttribute('data-gallery-download') === 'true');

        var overlayOpened = false,
            activeIndex = 0,
            overlay = createOverlay(),
            overlaySize = '',
            overlayGalleryList = '',
            lastFocusedElement = '',
            focusableElements = [],
            firstFocusableEl,
            lastFocusableElement,
            fabasoftElements,
            loadedElements = 0,
            translateX;

        $(gallery).find('.gallery__thumbnail').on('load', function () {
            loadedElements++;
        });

        var fabasoftId = gallery.getAttribute('data-fabasoft-root-id');
        if (fabasoftId) {
            setupFabasoft(fabasoftId);
        } else {
            setupThumbnails();
            setupOverlayHTML();
            initEventListener();
        }

        function getGalleryList() {
            var galleryList = gallery.querySelector(classes['galleryList']);

            if (!galleryList) {
                galleryList = document.createElement('ul');
                galleryList.classList.add(classNames['galleryList']);
                gallery.appendChild(galleryList);
            }

            return galleryList;
        }

        function setupFabasoft(fabasoftId) {
            loadFabasoftScript();
            loadFabasoftData($(gallery), fabasoftId);
        }

        function loadFabasoftScript() {
            if (settings.loadedFabasoftElements) {
                return;
            }
            settings.loadedFabasoftElements = true;

            if (!document.getElementById("fabasoft-script")) {
                var script = document.createElement('script');
                script.type = 'text/javascript';
                script.id = "fabasoft-script";
                script.src = "https://at.cloud.fabasoft.com/folio/fscasp/content/tmp/FSCVENVUI_1_1001/fsc.webcontent.js";
                document.head.appendChild(script);
            }
        }

        function loadFabasoftData($elem, fabasoftId) {
            setTimeout(function () {
                if (typeof fsc != 'undefined' && typeof fsc.webcontent != 'undefined') {
                    setTimeout(function () {
                        var fscwebcontentCalldata = getFscwebcontentCalldata(fabasoftId);

                        fsc.webcontent.setSuccessCallback(function successcallback(data) {
                            if ($elem.data("fabasoftimage")) {
                                createFabasoftImage($elem, data);
                            } else {
                                createFabasoftGallery($elem, data);
                            }

                            if ($elem.data("nextGallery")) {
                                initGallery($elem.data("nextGallery"));
                            }
                        });

                        fsc.webcontent.setErrorCallback(function errorcallback(data) {
                            if (data && data.errors && console && console.log) {
                                console.log(data.errors.apietext);
                            }
                        });

                        fsc.webcontent.render(fscwebcontentCalldata);
                    }, 10)

                } else {
                    loadFabasoftData($elem, fabasoftId)
                }
            }, 100)
        }

        function createFabasoftImage($elem, data) {
            try {
                if (data.children_level1) {
                    var description = data.children_level1.dadescription && data.children_level1.dadescription.value ? data.children_level1.dadescription.value : "";
                    var copyright = data.children_level1.daliccopyright && data.children_level1.daliccopyright.value ? data.children_level1.daliccopyright.value : "";
                    $elem.html('<img src="' + data.children_level1.largepreviewurl.value + '" alt="' + description + '" title="' + copyright + '" />');
                } else if (data.largepreviewurl) {
                    var description = data.dadescription && data.dadescription.value ? data.dadescription.value : "";
                    var copyright = data.daliccopyright && data.daliccopyright.value ? data.daliccopyright.value : "";
                    $elem.html('<img src="' + data.largepreviewurl.value + '" alt="' + description + '" title="' + copyright + '" />');
                }
            } catch (e) {
                console.log(e);
            }
        }

        function createFabasoftGallery($elem, data) {
            var useTitle = $elem.data('fabasoft-use-title');

            var galleryName = data.objname && data.objname.value ? data.objname.value : "",
                defaultCopyright = data.daliccopyright && data.daliccopyright.value ? data.daliccopyright.value : "";

            if ($elem.attr('data-gallery-title').length <= 0) $elem.attr('data-gallery-title', galleryName);


            if (!data.children_level1) {
                fabasoftElements = 0;
            } else if (Array.isArray(data.children_level1)) {
                fabasoftElements = data.children_level1.length;
                $.each(data.children_level1, function (index, element) {
                    addFabasoftElement(element, defaultCopyright, galleryName, index, $elem);
                });
            } else {
                fabasoftElements = 1;
                addFabasoftElement(data.children_level1, defaultCopyright, galleryName, 0, $elem);
            }

            $(gallery).find('.gallery__thumbnail').on('load', function () {
                loadedElements++;
            });

            setVariables();
        }

        function setVariables() {
            setTimeout(function () {
                if (fabasoftElements == loadedElements) {
                    galleryList = gallery.querySelector(classes['galleryList']),
                        galleryListItems = gallery.querySelectorAll(classes['galleryListItem']),
                        galleryThumbnailLinks = gallery.querySelectorAll(classes['galleryThumbnailLinks']),
                        galleryThumbnails = gallery.querySelectorAll(classes['galleryThumbnails']);
                    galleryWidth = galleryList.clientWidth - settings.borderWidth;

                    setupThumbnails();
                    setupOverlayHTML();
                    initEventListener();
                } else {
                    setVariables();
                }
            }, 100);
        }

        function getFscwebcontentCalldata(fabasoftId) {
            return {
                target: "https://at.cloud.fabasoft.com/folio",  // The website from where the data should be retrieved.
                rootobject: fabasoftId,       // Fabasoft id
                sortby: ["COOSYSTEM_1_1_objname:UP:"],          // The columns that should be used for sorting. Possible modifiers: ":UP:", ":DOWN:"
                additionalcolumns: [
                    "FSCTERM_1_1001_objterms",
                    "FSCDIGITALASSETMGMT_1_1001_daaltname",
                    "FSCDIGITALASSETMGMT_1_1001_daliccopyright",
                    "FSCDIGITALASSETMGMT_1_1001_dadescription"
                ], // The list of properties that should be retrieved in addition to the default values
                maxleveldepth: 5,                               // The depth of children that should be considered (the maximum is 5).
                language: "en",                                 // The language in which the strings should be retrieved.
                locale: "en-US",                                // The locale in which the dates, times, ... should be retrieved.
                timezoneoffset: -120,                           // The timezone offset that should be considered for dates.
                datetimeformat: "dd/mm/yy hh:mm",               // The date and time format that should be considered for all dates and times (overrides the locale).
                containerid: "FscJSLibContainer0",              // The HTML element in which the result should be rendered.
                templateid: "not required",                     // Not required in this sample since we do not want to render the response with Mustache.
                strings: ["FSCVENV_1_1001_StrExpand", "FSCVENV_1_1001_StrCollapse", "FSCSCM_1_1001_StrType"], // Additional strings that are returned from the webservice
                dataonly: true                                  //
            };
        }


        function setupThumbnails() {
            if ($('body').hasClass('dom-ready')) {

                galleryWidth = galleryList.clientWidth - settings.borderWidth;

                var elementsInARow = [],
                    rowWidth = 0,
                    remainingWidth = galleryWidth;

                for (var i = 0; i < galleryThumbnails.length; i++) {
                    var thumbnail = galleryThumbnails[i];

                    var aspectRatio = thumbnail.width / thumbnail.height;
                    var calculatedWidth = Math.round(aspectRatio * settings.defaultRowHeight);

                    if (remainingWidth >= calculatedWidth) {

                        thumbnail.style.width = calculatedWidth + "px";
                        thumbnail.style.height = settings.defaultRowHeight + "px";

                        elementsInARow.push(i);
                        rowWidth += calculatedWidth;

                        remainingWidth -= calculatedWidth - settings.borderWidth;
                    } else {
                        if (remainingWidth < 150) {
                            recalculateImagesInARow(elementsInARow, rowWidth);
                            elementsInARow = [];
                            rowWidth = 0;

                            thumbnail.style.width = calculatedWidth + "px";
                            thumbnail.style.height = settings.defaultRowHeight + "px";

                            elementsInARow.push(i);
                            rowWidth += calculatedWidth;
                            remainingWidth = galleryWidth - calculatedWidth - settings.borderWidth;

                        } else {

                            thumbnail.style.width = calculatedWidth + "px";
                            thumbnail.style.height = settings.defaultRowHeight + "px";

                            elementsInARow.push(i);
                            rowWidth += calculatedWidth;
                            remainingWidth = galleryWidth;
                            recalculateImagesInARow(elementsInARow, rowWidth);
                            elementsInARow = [];
                            rowWidth = 0;
                        }
                    }
                }

                gallery.classList.remove('gallery--loading');
                const anchorIndex = window.location.href.indexOf("#");
                if (anchorIndex !== (-1)) {
                    const relativeAnchor = window.location.href.substring(anchorIndex + 1);
                    document.getElementById(relativeAnchor).scrollIntoView();
                }
            } else {
                setTimeout(function () {
                    setupThumbnails();
                }, 100);
            }
        }

        function recalculateImagesInARow(elementsInARow, rowWidth) {

            var borderPx = elementsInARow.length * settings.borderWidth;
            var rowRatio = (galleryWidth - borderPx) / rowWidth;

            for (var i = 0; i < elementsInARow.length; i++) {
                var thumbnail = galleryThumbnails[elementsInARow[i]];

                thumbnail.style.width = Math.round(thumbnail.width * rowRatio) + "px";
                thumbnail.style.height = thumbnail.height * rowRatio + "px";
            }
            ;
        }


        /** Gallery Overlay Starts Here */
        function createOverlay() {
            var overlay = document.createElement('div');
            overlay.classList.add(classNames['overlay']);
            overlay.classList.add(classNames['overlayClosed']);
            overlay.setAttribute('aria-labelledby', 'overlay-title-' + galleryId);
            overlay.setAttribute('aria-hidden', 'true');
            overlay.setAttribute('role', 'dialog');
            overlay.setAttribute('aria-modal', 'true');

            document.body.appendChild(overlay);

            return overlay;
        }

        function setupOverlayHTML() {
            var overlayHeader = document.createElement('div');
            overlayHeader.classList.add(classNames['overlayHeader']);

            var buttonWrapper = document.createElement('div');
            buttonWrapper.classList.add(classNames['overlayButtons']);

            var closeButton = document.createElement('button');
            closeButton.classList.add(classNames['overlayButton']);
            closeButton.classList.add(classNames['overlayClose']);
            closeButton.innerHTML = "<span class='ic-close2' aria-hidden='true'></span>";
            closeButton.innerHTML += "<span class='hide-accessible'>" + getLabel("close") + "</span>";

            if (downloadEnabled) {
                var downloadButton = document.createElement('a');
                downloadButton.setAttribute('href', '#');
                downloadButton.setAttribute('target', '_blank');
                downloadButton.setAttribute('download', '');
                downloadButton.classList.add(classNames['overlayButton']);
                downloadButton.classList.add(classNames['overlayDownload']);
                //downloadButton.classList.add('ic-download');
                //downloadButton.innerHTML = "<span class='hide-accessible'>"+ getLabel("download") +"</span>";
            }

            // var zoomInButton = document.createElement('button');
            // zoomInButton.classList.add(classNames['overlayButton']);
            // zoomInButton.classList.add(classNames['overlayZoomIn']);
            // zoomInButton.classList.add('ic-zoomin');
            // zoomInButton.innerHTML = "<span class='hide-accessible'>"+ getLabel("zoomIn") +"</span>";

            // var zoomOutButton = document.createElement('button');
            // zoomOutButton.classList.add(classNames['overlayButton']);
            // zoomOutButton.classList.add(classNames['overlayZoomOut']);
            // zoomOutButton.classList.add('ic-zoomout');
            // zoomOutButton.innerHTML = "<span class='hide-accessible'>"+ getLabel("zoomOut") +"</span>";

            var title = gallery.getAttribute('data-gallery-title') ? gallery.getAttribute('data-gallery-title') : getLabel("gallery");
            var overlayTitle = document.createElement('h2');
            overlayTitle.innerHTML = title;
            overlayTitle.setAttribute('id', 'overlay-title-' + galleryId);
            overlayTitle.setAttribute('aria-live', 'polite');
            overlayTitle.classList.add(classNames['overlayTitle']);

            var overlayContent = document.createElement('div');
            overlayContent.classList.add(classNames['overlayContent']);

            if (downloadEnabled) {
                buttonWrapper.appendChild(downloadButton);
            }
            // buttonWrapper.appendChild(zoomOutButton);
            // buttonWrapper.appendChild(zoomInButton);
            buttonWrapper.appendChild(closeButton);


            overlayHeader.appendChild(overlayTitle);
            overlayHeader.appendChild(buttonWrapper);
            overlay.appendChild(overlayHeader);
            overlay.appendChild(overlayContent);

            if (galleryListItems.length > 1) {
                var navigation = document.createElement('div');
                navigation.classList.add(classNames['overlayControls']);

                var forwardBtn = document.createElement('button');
                forwardBtn.classList.add(classNames['overlayControl']);
                forwardBtn.classList.add(classNames['overlayControlForward']);
                forwardBtn.innerHTML = '<span class="ic-arrow2-right" aria-hidden="true"></span><span class="hide-accessible">' + getLabel('goForward') + '</span>';
                forwardBtn.addEventListener('click', function (event) {
                    event.preventDefault();
                    goForward();
                });

                var backwardBtn = document.createElement('button');
                backwardBtn.classList.add(classNames['overlayControl']);
                backwardBtn.classList.add(classNames['overlayControlBackward']);
                backwardBtn.innerHTML = '<span class="ic-arrow2-left" aria-hidden="true"></span><span class="hide-accessible">' + getLabel('goBackward') + '</span>';
                backwardBtn.addEventListener('click', function (event) {
                    event.preventDefault();
                    goBackward();
                });

                navigation.appendChild(backwardBtn);
                navigation.appendChild(forwardBtn);
                overlay.appendChild(navigation);
            }
        }

        function initEventListener() {
            for (var i = 0; i < galleryThumbnailLinks.length; i++) {
                var thumnailLink = galleryThumbnailLinks[i];

                thumnailLink.addEventListener('click', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    lastFocusedElement = this;
                    openOverlay(this);
                });
            }

            var closeButton = overlay.querySelector(classes['overlayClose']);
            closeButton.addEventListener('click', function (event) {
                event.preventDefault();
                closeOverlay();
            });

            // var zoomInButton = overlay.querySelector(classes['overlayZoomIn']);
            // zoomInButton.addEventListener('click', function(event) {
            //     event.preventDefault();
            //     var activeImg = overlay.querySelector(classes['overlayGalleryListItemActive'] + ' img');
            //     var currentScale = parseInt(activeImg.getAttribute('data-scale') ? activeImg.getAttribute('data-scale') : 1, 10);

            //     currentScale++;

            //     if(currentScale <= 10) {
            //         activeImg.setAttribute('data-scale', currentScale);
            //     }

            //     updateZoomButtons();
            // });

            // var zoomOutButton = overlay.querySelector(classes['overlayZoomOut']);
            // zoomOutButton.addEventListener('click', function(event) {
            //     event.preventDefault();

            //     var activeImg = overlay.querySelector(classes['overlayGalleryListItemActive'] + ' img');
            //     var currentScale = parseInt(activeImg.getAttribute('data-scale') ? activeImg.getAttribute('data-scale') : 1, 10);

            //     currentScale--;

            //     if(currentScale >= 1) {
            //         activeImg.setAttribute('data-scale', currentScale);
            //     }

            //     updateZoomButtons();
            // });

            overlay.addEventListener('click', function (event) {
                var target = event.target;
                var activeElement = overlay.querySelector(classes['overlayGalleryListItemActive']);

                if (target == activeElement) {
                    event.preventDefault();
                    closeOverlay(overlay);
                }
            });

            focusableElements = overlay.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');
            firstFocusableEl = focusableElements[0];
            lastFocusableElement = focusableElements[focusableElements.length - 1];

            document.addEventListener("keydown", function (event) {
                var KEY_ESC = 'Escape',
                    KEY_TAB = 'Tab',
                    KEY_LEFT_ARROW = 'ArrowLeft',
                    KEY_RIGHT_ARROW = 'ArrowRight';

                if (overlayOpened) {
                    if (event.key == KEY_TAB && focusableElements.length == 1) {
                        event.preventDefault();
                    } else if (event.key == KEY_TAB && event.shiftKey) {
                        if (document.activeElement == firstFocusableEl) {
                            event.preventDefault();
                            lastFocusableElement.focus();
                        }
                    } else if (event.key == KEY_TAB) {
                        if (document.activeElement == lastFocusableElement) {
                            event.preventDefault();
                            firstFocusableEl.focus();
                        }
                    } else if (event.key == KEY_ESC) {
                        closeOverlay(overlay);
                    } else if (event.key == KEY_LEFT_ARROW) {
                        goBackward();
                    } else if (event.key == KEY_RIGHT_ARROW) {
                        goForward();
                    }
                }
            });

            var resizeTimer;

            $(window).on('resize', function (e) {
                if (overlayOpened) {
                    moveGallery(getIndex().activeIndex);
                }


                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(function () {
                    setupThumbnails();
                }, 250);

            });
        }

        function openOverlay(target) {
            overlay.classList.remove('overlay--closed');
            overlay.setAttribute('aria-hidden', "false");

            if (overlay.querySelector(classes['overlayContent']).innerHTML.length <= 0) {
                var galleryItems = galleryListItems;
                overlaySize = overlay.clientWidth;

                if (galleryItems.length > 0) {
                    overlayGalleryList = document.createElement('ul');
                    overlayGalleryList.classList.add('overlay__gallery-list');

                    for (var i = 0; i < galleryItems.length; i++) {
                        var overlayGalleryListItem = document.createElement('li');
                        overlayGalleryListItem.classList.add(classNames['overlayGalleryListItem']);
                        var touchStartX = 0;
                        var touchEndX = 0;

                        var orgLink = galleryItems[i].querySelector('a');
                        if (orgLink == target) {
                            activeIndex = i;
                            overlayGalleryListItem.classList.add(classNames['overlayGalleryListItemActive']);
                        }

                        var orgImg = galleryItems[i].querySelector('img');

                        var galleryItem;

                        if (orgImg.getAttribute('data-video-url') && orgImg.getAttribute('data-video-url').length > 0) {
                            galleryItem = document.createElement('video');
                            galleryItem.setAttribute('src', orgImg.getAttribute('data-video-url'));
                            galleryItem.setAttribute('title', orgImg.getAttribute('title'));
                            galleryItem.setAttribute('preload', "metadata");
                            galleryItem.setAttribute('controls', true);
                            galleryItem.setAttribute('poster', orgImg.getAttribute('src'));
                            galleryItem.classList.add('overlay__gallery-video');
                            galleryItem.classList.add('overlay__gallery-item');

                        } else {
                            galleryItem = document.createElement('img');
                            galleryItem.setAttribute('src', orgImg.getAttribute('data-big-src'));
                            galleryItem.setAttribute('alt', orgImg.getAttribute('alt'));
                            galleryItem.setAttribute('title', orgImg.getAttribute('title'));
                            galleryItem.setAttribute('data-download', orgImg.getAttribute('data-download'));
                            galleryItem.classList.add('overlay__gallery-img');
                            galleryItem.classList.add('overlay__gallery-item');
                        }


                        var imgCaption = orgImg.getAttribute('data-subhtml');
                        if (imgCaption) {
                            var imgCaptionElement = document.createElement('div');
                            imgCaptionElement.classList.add('overlay__img-caption');
                            imgCaptionElement.innerHTML = imgCaption;
                            overlayGalleryListItem.appendChild(imgCaptionElement);
                        }

                        galleryItem.addEventListener('touchstart', function (e) {
                            e.preventDefault();
                            touchStartX = e.changedTouches.item(0).clientX;
                        });

                        galleryItem.addEventListener('touchend', function (e) {
                            e.preventDefault();
                            touchEndX = e.changedTouches.item(0).clientX;

                            if (touchStartX - 50 > touchEndX) {
                                goForward();
                            } else if (touchStartX + 50 < touchEndX) {
                                goBackward();
                            } else {
                                overlayGalleryList.style.transitionProperty = "transform"
                                overlayGalleryList.style.transitionDuration = "0.3s";
                                overlayGalleryList.style.transform = "translate(" + translateX + "px, 0)"
                            }
                        });

                        galleryItem.addEventListener('touchmove', function (e) {
                            e.preventDefault();

                            var touchMoveX = e.changedTouches.item(0).clientX;

                            if (touchStartX > touchMoveX + 5) {
                                doMove(touchStartX - touchMoveX);

                            } else if (touchStartX < touchMoveX - 5) {
                                doMove(touchStartX - touchMoveX);
                            }
                        });


                        overlayGalleryListItem.appendChild(galleryItem);
                        overlayGalleryList.appendChild(overlayGalleryListItem);
                    }

                }
            } else {
                activeIndex = target.getAttribute('data-index') ? parseInt(target.getAttribute('data-index')) : 0;
            }


            overlay.querySelector('.overlay__content').appendChild(overlayGalleryList)

            moveGallery(activeIndex);
            disableAccessToContent();
            overlay.querySelector(classes['overlayClose']).focus();
            overlayOpened = true;


            overlay.classList.add('overlay--opened');
        }

        function doMove(distance) {
            overlayGalleryList.style.transitionProperty = "transform";
            overlayGalleryList.style.transitionDuration = "0.3s";
            overlayGalleryList.style.transitionProperty = "transform";
            overlayGalleryList.style.transform = "translate(" + (translateX - distance) + "px, 0)";
        }

        function updateDownloadLink(href) {
            if (href && href != "false" && downloadEnabled) {
                overlay.querySelector(classes['overlayDownload']).setAttribute('href', href);
                overlay.querySelector(classes['overlayDownload']).removeAttribute('disabled');
                overlay.querySelector(classes['overlayDownload']).innerHTML = "<span class='ic-download' aria-hidden='true'></span><span class='hide-accessible'>" + getLabel("download") + " " + getIndex().activeCount + "</span>";
            } else {
                overlay.querySelector(classes['overlayDownload']).setAttribute('disabled', true);
            }

            //overlay.querySelector(classes['overlayDownload']).setAttribute('href',href);
            //overlay.querySelector(classes['overlayDownload']).innerHTML = "<span class='hide-accessible'>"+ getLabel("download") + " " + getIndex().activeCount + "</span>";
        }

        // function updateZoomButtons() {
        //     var activeImg = overlay.querySelector(classes['overlayGalleryListItemActive'] + ' img');

        //     var currentScale = parseInt(activeImg.getAttribute('data-scale') ? activeImg.getAttribute('data-scale') : 1, 10);

        //     var zoomInButton = overlay.querySelector(classes['overlayZoomIn']);
        //     var zoomOutButton = overlay.querySelector(classes['overlayZoomOut']);

        //     var zoomInText = getLabel("zoomIn") + " " + (currentScale + 1);
        //     var zoomOutText = getLabel("zoomOut") + " " + (currentScale - 1);
        //     var disableZoomIn = false;
        //     var disableZoomOut = false;

        //     if(currentScale == 1) {
        //         zoomOutText = getLabel("zoomOutMax");
        //         disableZoomOut = true;

        //     } else if (currentScale == 10){
        //         zoomInText = getLabel("zoomInMax");

        //         disableZoomIn = true;
        //     }

        //     if (disableZoomIn) {
        //         zoomInButton.setAttribute('disabled', disableZoomIn);
        //     } else {
        //         zoomInButton.removeAttribute('disabled');
        //     }

        //     if (disableZoomOut) {
        //         zoomOutButton.setAttribute('disabled', disableZoomOut);
        //     } else {
        //         zoomOutButton.removeAttribute('disabled');
        //     }


        //     zoomInButton.querySelector('.hide-accessible').innerHTML = zoomInText;
        //     zoomOutButton.querySelector('.hide-accessible').innerHTML = zoomOutText;


        // }

        function updateTitle() {
            var title = gallery.getAttribute('data-gallery-title') ? gallery.getAttribute('data-gallery-title') : getLabel("gallery");

            overlay.querySelector(classes['overlayTitle']).innerHTML = title + " <span class=\"hide-accessible\">Bild </span>" + getIndex().activeCount + "<span aria-hidden=\"true\"> / </span> <span class=\"hide-accessible\"> von </span>" + galleryListItems.length + "</span>";
        }

        function getIndex() {
            var index = activeIndex,
                forwardIndex = activeIndex < galleryListItems.length - 1 ? activeIndex + 1 : 0;
            backwarIndex = activeIndex > 0 ? activeIndex - 1 : galleryListItems.length - 1,
                activeCount = index + 1,
                forwardCount = forwardIndex + 1,
                backwardCount = backwarIndex + 1;

            return {
                activeIndex: index,
                forwardIndex: forwardIndex,
                backwardIndex: backwarIndex,
                activeCount: activeCount,
                forwardCount: forwardCount,
                backwardCount: backwardCount
            }
        }


        function goForward() {
            moveGallery(getIndex().forwardIndex);

        }

        function goBackward() {
            moveGallery(getIndex().backwardIndex);
        }

        function updateControlButtons() {
            var forwardBtnText = overlay.querySelector('.overlay__control--forward .hide-accessible');
            var backwardBtnText = overlay.querySelector('.overlay__control--backward .hide-accessible');

            if (forwardBtnText && backwardBtnText) {
                forwardBtnText.innerHTML = getLabel("goToImage") + " " + getIndex().forwardCount + " " + getLabel("outOf") + " " + galleryListItems.length;
                backwardBtnText.innerHTML = getLabel("goToImage") + " " + getIndex().backwardCount + " " + getLabel("outOf") + " " + galleryListItems.length;
            }
        }

        function moveGallery(index) {
            var videoItem = overlayGalleryList.querySelector(classes['overlayGalleryListItemActive'] + ' video');
            if (videoItem && !videoItem.paused) {
                videoItem.pause();
            }

            activeIndex = index;
            translateX = overlayGalleryList.querySelectorAll(classes['overlayGalleryListItem'])[index].clientWidth * index * -1;
            var xPosition = translateX + 'px';
            overlayGalleryList.setAttribute('style', 'transform: translate(' + xPosition + ',0)');
            overlayGalleryList.querySelector(classes['overlayGalleryListItemActive']).classList.remove(classNames['overlayGalleryListItemActive']);
            overlayGalleryList.querySelectorAll(classes['overlayGalleryListItem'])[index].classList.add(classNames['overlayGalleryListItemActive']);


            updateDownloadLink(overlay.querySelector(classes['overlayGalleryListItemActive'] + ' .overlay__gallery-item').getAttribute('data-download'));
            updateControlButtons();
            updateTitle();
            // updateZoomButtons();
            updateAccessToGallery();

        }

        function updateAccessToGallery() {
            var overlayItems = overlayGalleryList.querySelectorAll(classes['overlayGalleryListItem']);

            for (var i = 0; i < overlayItems.length; i++) {
                overlayItems[i].setAttribute('tabindex', '-1');
                overlayItems[i].setAttribute('aria-hidden', 'true');

                var video = overlayItems[i].querySelector('video');

                if (video) {
                   video.setAttribute('tabindex', '-1');
                   video.setAttribute('aria-hidden', 'true');
                }

            }

            overlayGalleryList.querySelector(classes['overlayGalleryListItemActive']).removeAttribute('tabindex');
            overlayGalleryList.querySelector(classes['overlayGalleryListItemActive']).removeAttribute('aria-hidden');


            var activeVideo = overlayGalleryList.querySelector(classes['overlayGalleryListItemActive'] + " video");

            if (activeVideo) {
                activeVideo.removeAttribute('tabindex');
                activeVideo.removeAttribute('aria-hidden');
            }
        }

        function disableAccessToContent() {
            var contentElements = document.querySelectorAll('body > *');
            for (var i = 0; i < contentElements.length; i++) {
                if (contentElements[i] != overlay) {
                    if (contentElements[i].getAttribute('aria-hidden')) {
                        contentElements[i].setAttribute('data-aria-hidden', contentElements[i].getAttribute('aria-hidden'));
                    }
                    contentElements[i].setAttribute('aria-hidden', 'true');
                }
            }
        }

        function closeOverlay() {
            overlay.classList.add('overlay--closed');
            overlay.classList.remove('overlay--opened');
            overlay.setAttribute('aria-hidden', "true");

            lastFocusedElement.focus();
            lastFocusedElement = '';

            enableAccessToContent();
            overlayOpened = false;
        }

        function enableAccessToContent() {
            var contentElements = document.querySelectorAll('body > *');
            for (var i = 0; i < contentElements.length; i++) {
                if (contentElements[i] != overlay) {
                    if (contentElements[i].getAttribute('data-aria-hidden')) {
                        contentElements[i].setAttribute('aria-hidden', contentElements[i].getAttribute('data-aria-hidden'));
                    } else {
                        contentElements[i].removeAttribute('aria-hidden');
                    }
                }
            }
        }

    };
};
