
$.fn.mashupPlugin = function (options) {
	var pluginName = "mashupPlugin";

	// default options.
	var settings = $.extend({
		// These are the defaults.
		// color: "#556b2f",
		// backgroundColor: "white"
	}, options);
	// Do something to each element here.

	var $this,
		ajaxUrl,
		$mashupContainer,
		$mashupTiles,
		$moreButton,
		$resetButton,
		requestedAjaxUrl,
		baseUrl,
		language,
		txtMoreButton,
		txtShowAll;

	return this.each(function () {
		$this = $(this);
		ajaxUrl = $(this).attr('data-url');
		requestedAjaxUrl = ajaxUrl;
		baseUrl = $(this).attr('data-base-url');
		language = $(this).attr('data-language');
		if (language === undefined || language === null || language === "") {
			language = "en";
		}

		txtMoreButton = $this.attr('data-read-more');
		txtShowAll = $this.attr('data-show-all');

		$mashupContainer = $this.find('.mashup .row');
		$mashupTiles = $this.find('#mashup-tiles');

		initEventListener();
		ajaxUrl = addLanguageParameter(ajaxUrl);
		getMashups(ajaxUrl, true);
	});
		
	function initEventListener() {
		var mashupCategories = $this.find("li .mashup-categorie-tab");
		mashupCategories.on('click', function(e) {
			e.preventDefault();
			$liParent = $(this).parent('li');

			if($liParent.hasClass('active')) return;

			mashupCategories.parent('li').removeClass('active');
			$liParent.addClass('active');

			var tempAjaxUrl = ajaxUrl,
				categoryId = $(this).attr("data-mashup-category");
			if(categoryId) {
				if(tempAjaxUrl.indexOf('?') > -1) {
					tempAjaxUrl += "&category=" + categoryId
				} else {
					tempAjaxUrl += "?category=" + categoryId
				}
			}
			getMashups(tempAjaxUrl, true);

		});


		// Presseinfo
		var mashupPressefilter = $this.find(".search-criterion");
		mashupPressefilter.on('change', function(e) {
			e.preventDefault();
			var selection = $(this).find('select');
			var paramKey = selection.attr('name');
			var paramValue = selection.val()
			
			if (paramValue && paramKey) {
				requestedAjaxUrl = updateQueryStringParameter(requestedAjaxUrl, paramKey, paramValue);
			}

			getMashups(requestedAjaxUrl, true);

		});
	}


	function updateQueryStringParameter(uri, key, value) {
		var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
		var separator = uri.indexOf('?') !== -1 ? "&" : "?";
		if (uri.match(re)) {
		  return uri.replace(re, '$1' + key + "=" + value + '$2');
		}
		else {
		  return uri + separator + key + "=" + value;
		}
	  }

	function getMashups(url, replaceHTML) {
		$.get(url, function (data) {
			var dataJSON;
			if(typeof(data) == 'object'){
				dataJSON = data
			} else {
				dataJSON = JSON.parse(data);
			}
			if(replaceHTML) {
				$mashupContainer.html("");
			}

			if(dataJSON.results.length > 0) {
				for(var i = 0; i < dataJSON.results.length; i++) {
					$mashupContainer.append(renderMashupItem(dataJSON.results[i]));
				}

				$('.js-lazy-image').lazyLoadImagesPlugin();

				if(dataJSON.hasMore) {
					updateMoreButton(url, dataJSON.offset, dataJSON.nextMax);
				} else {
					removeMoreButton();
					removeResetButton();
				}

			} else {
				$mashupContainer.html("<p class='center-text'>" + $this.attr('data-no-result') + "</p>")
				addResetButton();
			}
		});
	}

	function updateMoreButton(tempAjaxUrl, offset, max) {	
		addMoreButton(tempAjaxUrl);

		$moreButton.attr('href', updateURLParameter(tempAjaxUrl, 'max', max));
		$moreButton.attr('href', updateURLParameter($moreButton.attr('href'), 'offset', offset));
		$moreButton.attr('href', updateURLParameter($moreButton.attr('href'), 'firstRendered', "true"));
	}

	function addMoreButton(tempAjaxUrl) {
		$('.mup-more-button').remove();

		var moreButton = '';
			moreButton += '<div class="mup-more-button">'
			moreButton += '<div class="button-text-box">'
			moreButton += '<a href="'+ tempAjaxUrl +'" class="button-more button-more-next-mashups-page pointer-anchor">'
			moreButton += '<span class="ic-plus1" aria-hidden=\"true\"></span> ' + txtMoreButton + '</a>'
			moreButton += '</div></div>'


		$mashupTiles.find('.mashup-container').append($(moreButton));
		
		$moreButton = $mashupTiles.find('.button-more')
		$moreButton.on('click', function(e) {
			e.preventDefault();
			getMashups($(this).attr('href'), false);
		});
	}

	function addResetButton() {
		$('.mup-more-button').remove();

		var resetButton = '';
			resetButton += '<div class="mup-more-button">'
			resetButton += '<div class="button-text-box">'
			resetButton += '<a href="'+ baseUrl +'" class="button-more button-more--reset pointer-anchor">'
			resetButton += '<span class="ic-plus1" aria-hidden=\"true\"></span> ' + txtShowAll + '</a>'
			resetButton += '</div></div>'


		$mashupTiles.find('.mashup-container').append($(resetButton));
		
		$resetButton = $mashupTiles.find('.button-more--reset')
		$resetButton.on('click', function(e) {
			e.preventDefault();
			getMashups($(this).attr('href'), true);
			$this.find('select').prop('selectedIndex',0);
			$this.find('.mup-category-list li.active').removeClass('active');
			$this.find('.mup-category-list li:first-child').addClass('active');
		});
	}

	function removeMoreButton() {
		if (!$moreButton) return;
		$mashupTiles.find('.mup-more-button').remove();
		$moreButton = '';
	}

	function removeResetButton() {
		if (!$resetButton) return;
		$mashupTiles.find('.mup-more-button').remove();
		$resetButton = '';
	}

	function renderMashupItem(mashupObject) {
		var backupClass = mashupObject.imgClass.indexOf("mashup-backup") !== -1 ? "mup-statement" : "";
		var tempHTML = '';
		tempHTML += '<li class="col-1 mup-col-1">';
		tempHTML += '<img src="' + (mashupObject.imgScr ?? mashupObject.imgSrc) +'" data-src="' + mashupObject.dataSrc +'" data-srcset="' + mashupObject.dataSrcSet +'" class="'+ mashupObject.imgClass +' center" alt="'+ mashupObject.imgAlt +'" title="'+ mashupObject.imgTitle +'" />';
		tempHTML += '<a class="mup-content ' + backupClass + '" href="' + mashupObject.linkHref + '">';
		tempHTML += '<div>';

		if (mashupObject.category && $this.attr('data-show-title') == "true") {
			tempHTML += '<h3 class="mup-title">' + mashupObject.category + '</h3>'
		}

		tempHTML += '<div class="mup-multiline-truncation">' + mashupObject.title + '</div>';
		if (mashupObject.releaseDate.length > 0 && (mashupObject.hideDate == false || mashupObject.hideDate == "false")) {
			var releaseDate = mashupObject.releaseDate;
			
			if(document.documentElement.lang == 'hu') {
				releaseDate = getFormatedReleaseDate(releaseDate);
			}

			tempHTML += '<div class="mup-category-icon"><span class="ic-clock" aria-hidden=\"true\"></span>' + releaseDate + '</div>';
		}
		tempHTML += '</div>';
		tempHTML += '</a>';
		tempHTML += '</li>';
		return $(tempHTML);
	}

	function updateURLParameter(url, param, paramVal){
		var newAdditionalURL = "";
		var tempArray = url.split("?");
		var baseURL = tempArray[0];
		var additionalURL = tempArray[1];
		var temp = "";
		if (additionalURL) {
			tempArray = additionalURL.split("&");
			for (var i=0; i<tempArray.length; i++){
				if(tempArray[i].split('=')[0] != param){
					newAdditionalURL += temp + tempArray[i];
					temp = "&";
				}
			}
		}
	
		var rows_txt = temp + "" + param + "=" + paramVal;
		return baseURL + "?" + newAdditionalURL + rows_txt;
	}

	function getFormatedReleaseDate(dateString) {
		var dateWithoutSuffix = dateString.replace('-tól', '');
		var dateDay = dateWithoutSuffix.slice(-2);

		switch (dateDay) {
			case "01": return dateWithoutSuffix + '-től';
			case '02': return dateWithoutSuffix + '-től';
			case '03': return dateWithoutSuffix + '-tól';
			case '04': return dateWithoutSuffix + '-től';
			case '05': return dateWithoutSuffix + '-től';
			case '06': return dateWithoutSuffix + '-tól';
			case '07': return dateWithoutSuffix + '-től';
			case '08': return dateWithoutSuffix + '-tól';
			case '09': return dateWithoutSuffix + '-től';
			case '10': return dateWithoutSuffix + '-től';
			case '11': return dateWithoutSuffix + '-től';
			case '12': return dateWithoutSuffix + '-től';
			case '13': return dateWithoutSuffix + '-tól';
			case '14': return dateWithoutSuffix + '-től';
			case '15': return dateWithoutSuffix + '-től';
			case '16': return dateWithoutSuffix + '-tól';
			case '17': return dateWithoutSuffix + '-től';
			case '18': return dateWithoutSuffix + '-tól';
			case '19': return dateWithoutSuffix + '-től';
			case '20': return dateWithoutSuffix + '-tól';
			case '21': return dateWithoutSuffix + '-től';
			case '22': return dateWithoutSuffix + '-től';
			case '23': return dateWithoutSuffix + '-tól';
			case '24': return dateWithoutSuffix + '-től';
			case '25': return dateWithoutSuffix + '-től';
			case '26': return dateWithoutSuffix + '-tól';
			case '27': return dateWithoutSuffix + '-től';
			case '28': return dateWithoutSuffix + '-tól';
			case '29': return dateWithoutSuffix + '-től';
			case '30': return dateWithoutSuffix + '-tól';
			case '31': return dateWithoutSuffix + '-től';
		}		
	}

	function addLanguageParameter(url) {
		if(url.indexOf('?') !== (-1)) {
			url += "&language=" + language;
		} else {
			url += "?language=" + language;
		}
		return url;
	}
};