$.fn.galleryPlugin = function (options) {
	var pluginName = "galleryPlugin";

	// default options.
	var settings = $.extend({
		loadedFabasoftElements: false
	}, options);
    // Do something to each element here


    return main(this);


    function main(galleries) {
        galleries.each(function(i,elm) {
            var $elm = $(elm),
                $nextGallery = galleries.eq(i + 1).length > 0 ? galleries.eq(i + 1) : null;

            $elm.data("nextGallery", $nextGallery);
        });

        if(galleries.length > 0) {
            initLightGallery($(galleries.get(0)));
        }
    }

    function initLightGallery($elem) {
        var fabasoftId = $elem.attr("data-fabasoft-root-id")
        if(fabasoftId) {
            loadFabasoftScript();
            loadFabasoftData($elem, fabasoftId)
        } else {
            setTimeout(function () {
                createLightGallery($elem);
            }, 10)
        }
    }


    function loadFabasoftScript() {
        if (settings.loadedFabasoftElements) return;
        settings.loadedFabasoftElements = true;

        if (!document.getElementById("fabasoft-script")) {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.id = "fabasoft-script";
            script.src = "https://at.cloud.fabasoft.com/folio/fscasp/content/tmp/FSCVENVUI_1_1001/fsc.webcontent.js";
            document.head.appendChild(script);
        }
    }

    function loadFabasoftData($elem, fabasoftId) {
        setTimeout(function () {
            if (typeof fsc != 'undefined' && typeof fsc.webcontent != 'undefined') {
                setTimeout(function () {
                    var fscwebcontentCalldata = getFscwebcontentCalldata(fabasoftId);
                    fsc.webcontent.setSuccessCallback(function successcallback(data) {
                        console.log(data);
                        if($elem.data("fabasoftimage")) {
                            createFabasoftImage($elem, data);
                        } else {
                            createFabasoftGallery($elem, data);
                        }
                    });

                    fsc.webcontent.setErrorCallback(function errorcallback(data) {
                        if (data && data.errors && console && console.log) {
                            console.log(data.errors.apietext);
                        }
                    });
                    console.log(fscwebcontentCalldata);
                    fsc.webcontent.render(fscwebcontentCalldata);

                }, 10)

            } else {
                loadFabasoftData($elem, fabasoftId)
            }
        }, 100)
    }

    function createFabasoftImage($elem, data) {
        try {
            if (data.children_level1) {
                $elem.html('<img src="' + data.children_level1.largepreviewurl.value + '" />');
            } else if (data.largepreviewurl) {
                $elem.html('<img src="' + data.largepreviewurl.value + '" />');
            }
        } catch (e) {
            console.log(e);
        }

        if ($elem.data("nextGallery")) initLightGallery($elem.data("nextGallery"));
    }

    function createFabasoftGallery($elem, data) {
        var defaultDescription = data.dadescription && data.dadescription.value ? data.dadescription.value : "",
            defaultCopyright = data.daliccopyright && data.daliccopyright.value ? data.daliccopyright.value : "";

        $.each(data.children_level1, function (index, element){

            var elementDescription = element.dadescription && element.dadescription.value ? element.dadescription.value : defaultDescription,
                elementCopyright = "© " + (element.daliccopyright && element.daliccopyright.value ? element.daliccopyright.value : defaultCopyright);


            var data = $("<a href=\"" + element.largepreviewurl.value +
                "\" data-download-url=\"" + element.downloadurl.value +
                "\" data-sub-html=\"<p>" + elementDescription + "</p><p>" + elementCopyright +
                "</p>\"><img src=\"" + element.thumbnailurl.value +
                "\" alt=\"" + elementDescription +
                "\" data-subtitle=\"" + elementDescription +
                "\" title=\"" + elementCopyright +
                "\" /><div class=\"demo-gallery-poster\">" +
                `<img src="${require('../../../lightGallery/dist/img/zoom.png')}">` +
                "</div></a>")
            $elem.append(data)
        });

        setTimeout(function () {
            createLightGallery($elem);
        }, 10)
    }


    function getFscwebcontentCalldata(fabasoftId) {
        return {
            target: "https://at.cloud.fabasoft.com/folio",  // The website from where the data should be retrieved.
            rootobject: fabasoftId,       // Fabasoft id
            sortby: ["COOSYSTEM_1_1_objname:UP:"],          // The columns that should be used for sorting. Possible modifiers: ":UP:", ":DOWN:"
            additionalcolumns: [
                "FSCTERM_1_1001_objterms",
                "FSCDIGITALASSETMGMT_1_1001_daaltname",
                "FSCDIGITALASSETMGMT_1_1001_daliccopyright",
                "FSCDIGITALASSETMGMT_1_1001_dadescription"
            ], // The list of properties that should be retrieved in addition to the default values
            maxleveldepth: 5,                               // The depth of children that should be considered (the maximum is 5).
            language: "en",                                 // The language in which the strings should be retrieved.
            locale: "en-US",                                // The locale in which the dates, times, ... should be retrieved.
            timezoneoffset: -120,                           // The timezone offset that should be considered for dates.
            datetimeformat: "dd/mm/yy hh:mm",               // The date and time format that should be considered for all dates and times (overrides the locale).
            containerid: "FscJSLibContainer0",              // The HTML element in which the result should be rendered.
            templateid: "not required",                     // Not required in this sample since we do not want to render the response with Mustache.
            strings: ["FSCVENV_1_1001_StrExpand", "FSCVENV_1_1001_StrCollapse", "FSCSCM_1_1001_StrType"], // Additional strings that are returned from the webservice
            dataonly: true                                  //
        };
    }

    function createLightGallery($elem) {
        $elem.justifiedGallery({
            border: 0
        }).on('jg.complete', function () {

            $elem.lightGallery({
                thumbnail: false,
                hideBarsDelay: 300000
            });

            addAccessibilityFunctionality($elem);

            if ($elem.data("nextGallery")) {
                initLightGallery($elem.data("nextGallery"));
            }
        });

    }

    function addAccessibilityFunctionality($elem) {
        $elem.on("onBeforeClose.lg", function() {
            $(".lg-actions > div").attr("tabindex", "-1");
            $(".lg-toolbar > span").attr("tabindex", "-1");
            $("*").not(".lg-outer *").removeAttr("tabindex");
            $("*[data-tabindex='0']").attr("tabindex", "0").removeAttr("data-tabindex");
            $("*[data-tabindex='-1']").attr("tabindex", "-1").removeAttr("data-tabindex");
            $('body').removeClass('js-gallery-ready');

            $elem.focusedElement.trigger('focus');
        });

        $elem.on("onAfterOpen.lg", function() {
            $elem.focusedElement = document.activeElement;

            $("*[tabindex='0']").attr("data-tabindex", "0");
            $("*[tabindex='-1']").attr("data-tabindex", "-1");
            $("*").not(".lg-outer *").attr("tabindex", "-1");

            $('.lg-actions > div').attr("tabindex", "0");


            $('.lg-close').attr("tabindex",0).trigger('focus');

            setTimeout(function() {
                $('body').addClass('js-gallery-ready');
            },300)
        });

        document.addEventListener('keyup', function(e) {
            if($('body').hasClass('js-gallery-ready')) {
                if(e.keyCode == 13 && $('.lg-close').is(':focus')) {
                    $elem.data("lightGallery").destroy();
                }

                if(e.keyCode == 13 && $('.lg-prev').is(':focus')) {
                    $elem.data("lightGallery").goToPrevSlide();
                }

                if(e.keyCode == 13 && $('.lg-next').is(':focus')) {
                    $elem.data("lightGallery").goToNextSlide();
                }
            }

        });
    }

};
